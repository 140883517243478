<tbody>
  <tr>
    <td>
      <table
        border="0"
        cellspacing="0"
        style="background-color: White"
        cellpadding="5"
        width="800px"
        align="center"
      >
      <tbody>
        <tr>
          <td width="50%">
            <div
              id="ctl00_ValidationSummary1"
              style="color: Red"
              [hidden]="!isValidationError"
            >
              <h4>Please correct the following errors</h4>
              <ul>
                <li *ngFor="let error of errMessages">
                  {{ error }} <p>
                </li>
              </ul>
            </div>
          </td>
          <td width="50%"></td>
        </tr>
      </tbody>
      </table>

      <div
        id="ctl00_pageContent_ModalPopupExtender_foregroundElement"
        style="display: none; position: fixed"
      >
        <div
          id="ctl00_pageContent_Panel1"
          class="modalPopup"
          style="display: none"
        >
          <div
            id="ctl00_pageContent_Panel3"
            style="
              cursor: move;
              background-color: #ffffff;
              border: solid 1px Gray;
              color: Black;
            "
          >
            <div>
              <br />
              <table width="90%" align="center">
                <tbody>
                  <tr>
                    <td
                      style="
                        color: White;
                        background-color: Blue;
                        font-weight: bold;
                      "
                      align="center"
                    >
                      Error Messages
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <div
                          id="ctl00_pageContent_dvError"
                          style="color: Red"
                        ></div>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style="text-align: center">
                <input

                  name="ctl00$pageContent$CancelButton"
                  value="OK"
                  id="ctl00_pageContent_CancelButton"
                  style="width: 100px"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ctl00_pageContent_pnlContent"

      >
        <table width="100%">
          <tbody>
            <tr>
              <td align="center">
                <h3>FINANCIAL CERTIFICATIONS</h3>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%">
          <tbody>
            <tr>
              <td>
                I. I certify that the organization named in this application
                has (and all of its member charity applicants have) submitted
                accurate financial documents, IRS Form 990 (minimum of pages
                1, 9, &amp; 10) or <b>IRS Form 990EZ page 1</b>  and/or all relevant notes and supplemental
                schedules with ending dates on or after June 30, {{campaignYear -1}} if you
                operate on a fiscal year or December 31, {{campaignYear -1}} if you operate
                on a calendar year. If your {{campaignYear}} annual report is available,
                please attach instead.<br />
                <table
                  id="ctl00_pageContent_ynJ_rblYesNo"
                  cellpadding="5"
                  border="0"
                >
                <tbody>
                  <tr>
                    <td>
                      <input
                        id="respJ_1"
                        type="radio"
                        name="respJ_1"
                        [value]="true"
                        [(ngModel)]="finCertsfcModel.respJ"
                      /><label for="respJ_1"
                        >YES</label
                      >
                    </td>
                    <td>
                      <input
                        id="respJ_0"
                        type="radio"
                        name="respJ_0"
                        [value]="false"
                        [(ngModel)]="finCertsfcModel.respJ"
                      /><label for="respJ_0"
                        >NO</label
                      >
                    </td>
                  </tr>
                </tbody>
                </table>
                <span
                  id="ctl00_pageContent_ynJ_rfvYesNo"
                  style="color: Red; display: none"
                ></span>
                <br />
                Copy of latest financial statements dated on or after June 30,
                {{campaignYear -1}} if you operate on a fiscal year or December 31, {{campaignYear -1}} if you
                operate on a calendar year. If your {{campaignYear}} annual report is
                available, please attach instead. (Attachment E)
                <table id="ctl00_pageContent_fuE_tblUpload">
                  <tbody>
                    <tr>
                      <td>
                        <span style="font-size: 75%; color: Blue"
                          >Please limit file size to 2048KB.</span
                        >
                        <app-seccfileuploader #attachE
                            [name]="'attachE'"
                            [uploadUrl]="'rest/secconlineapp/fincerts/uploadAttachmtE'"
                            [deleteUrl]="'secconlineapp/fincerts/deleteAttachmtE'"
                            [maxFileSize]="maxFileSz_finCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_finCert"
                            [maxFileCount]="maxFileCount_finCert"
                          >
                          </app-seccfileuploader>
                      </td>
                    </tr>

                  </tbody>
                </table>

                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span
                          id="ctl00_pageContent_fuE_lblError"
                          style="color: Red"
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div></div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                Copy of completed IRS Form 990 (not 990A) pages 1, 9 and 10
                only <b>or IRS Form 990EZ Page 1 </b> (Attachment F)
                <table id="ctl00_pageContent_fuF_tblUpload">
                  <tbody>
                    <tr>
                      <td>
                        <!--
                        <input
                          type="file"
                          name="ctl00$pageContent$fuF$fuFileUpload"
                          id="ctl00_pageContent_fuF_fuFileUpload"
                        />
                        -->
                        <span style="font-size: 75%; color: Blue"
                          >Please limit file size to 2048KB.</span
                        >
                      <app-seccfileuploader #attachF
                        [name]="'attachF'"
                        [uploadUrl]="'rest/secconlineapp/fincerts/uploadAttachmtF'"
                        [deleteUrl]="'secconlineapp/fincerts/deleteAttachmtF'"
                        [maxFileSize]="maxFileSz_finCert"
                        [allowMultiple]="false"
                        [fileType]="fileType_finCert"
                        [maxFileCount]="maxFileCount_finCert"
                      >
                      </app-seccfileuploader>
                      </td>
                    </tr>

                  </tbody>
                </table>

                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span
                          id="ctl00_pageContent_fuF_lblError"
                          style="color: Red"
                        ></span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <hr />
                &nbsp;
              </td>
            </tr>
            <tr>
              <td colspan="4" align="right">
                <input
                  type="image"
                  width="100"
                  name="ctl00$pageContent$btnNext"
                  id="ctl00_pageContent_btnNext"
                  src="assets/img/continue-button.jpg"
                  (click)="continue()"
                  style="border-width: 0px"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        id="ctl00_pageContent_ModalPopupExtender_backgroundElement"
        class="modalBackground"
        style="display: none; position: fixed; left: 0px; top: 0px"
      ></div>
    </td>
  </tr>
</tbody>
