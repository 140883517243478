import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  constructor(private activatedRoute: ActivatedRoute) { }
  isLoginError : boolean = false;

  ngOnInit(): void {

    const error = this.activatedRoute.snapshot.queryParams['error'];

    if(error=='true') {
      this.isLoginError = true;
    } else {
      this.isLoginError = false;
    }

  }


}
