<div>
  <!--FINANCIAL CERTS -->

  <tbody>
    <tr>
      <td>
        <table
          border="0"
          cellspacing="0"
          style="background-color: White"
          cellpadding="5"
          width="800px"
          align="center"
        >
        <tbody>
          <tr>
            <td width="50%">
              <div
                id="ctl00_ValidationSummary1"
                style="color: Red"
                [hidden]="!isValidationError"
              >
                <h4>Please correct the following errors</h4>
                <ul>
                  <li *ngFor="let error of errMessages">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </td>
            <td width="50%"></td>
          </tr>
        </tbody>
        </table>

        <div
          id="ctl00_pageContent_ModalPopupExtender_foregroundElement"
          style="display: none; position: fixed"
        >
          <div
            id="ctl00_pageContent_Panel1"
            class="modalPopup"
            style="display: none"
          >
            <div
              id="ctl00_pageContent_Panel3"
              style="
                cursor: move;
                background-color: #ffffff;
                border: solid 1px Gray;
                color: Black;
              "
            >
              <div>
                <br />
                <table width="90%" align="center">
                  <tbody>
                    <tr>
                      <td
                        style="
                          color: White;
                          background-color: Blue;
                          font-weight: bold;
                        "
                        align="center"
                      >
                        Error Messages
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <div
                            id="ctl00_pageContent_dvError"
                            style="color: Red"
                          ></div>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p style="text-align: center">
                  <input
                    type="submit"
                    name="ctl00$pageContent$CancelButton"
                    value="OK"
                    onclick='javascript:WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions("ctl00$pageContent$CancelButton", "", true, "", "", false, false))'
                    id="ctl00_pageContent_CancelButton"
                    style="width: 100px"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="ctl00_pageContent_pnlContent"
          onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ctl00_pageContent_btnNext')"
        >
          <!--<input type="image" name="ctl00$pageContent$Button1" id="ctl00_pageContent_Button1" src="Images/gif.gif" onclick="javascript:WebForm_DoPostBackWithOptions(new WebForm_PostBackOptions(&quot;ctl00$pageContent$Button1&quot;, &quot;&quot;, true, &quot;&quot;, &quot;&quot;, false, false))" style="border-width:0px;">-->

          <table width="100%">
            <tbody>
              <tr>
                <td align="center">
                  <h3>FINANCIAL CERTIFICATIONS</h3>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  H. I certify that the organization named in this application
                  accounts (and all of its member charity applicants account)
                  for its funds in accordance with Generally Accepted Accounting
                  Principles (GAAP).<br />
                  <table
                    id="ctl00_pageContent_ynI_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respI_1"
                            type="radio"
                            name="respI_1"
                            [value]="true"
                            [(ngModel)]="finCertsModel.respI"
                          /><label for="respI_1"
                            >YES</label
                          >
                        </td>
                        <td>
                          <input
                            id="respI_0"
                            type="radio"
                            name="respI_0"
                            [value]="false"
                            [(ngModel)]="finCertsModel.respI"
                          /><label for="respI_0)"
                            >NO</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span
                    id="ctl00_pageContent_ynI_rfvYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  I. I certify that the organization named in this application
                  has (and all of its member charity applicants have) submitted
                  accurate financial documents, IRS Form 990 (minimum of pages
                  1, 9, &amp; 10) or <b>IRS Form 990EZ page 1</b>  and/or all relevant notes and supplemental
                  schedules with ending dates on or after June 30, {{campaignYear -1}} if you
                  operate on a fiscal year or December 31, {{campaignYear -1}} if you operate
                  on a calendar year. If your {{campaignYear}} annual report is available,
                  please attach instead.<br />
                  <table
                    id="ctl00_pageContent_ynJ_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respJ_1"
                            type="radio"
                            name="respJ_1"
                            [value]="true"
                            [(ngModel)]="finCertsModel.respJ"
                          /><label for="respJ_1"
                            >YES</label
                          >
                        </td>
                        <td>
                          <input
                            id="respJ_0"
                            type="radio"
                            name="respJ_0"
                            [value]="false"
                            [(ngModel)]="finCertsModel.respJ"
                          /><label for="respJ_0)"
                            >NO</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span
                    id="ctl00_pageContent_ynJ_rfvYesNo"
                    style="color: Red; display: none"
                  ></span>
                  <br />
                  Copy of latest financial statements dated on or after June 30,
                  {{campaignYear -1}} if you operate on a fiscal year or December 31, {{campaignYear -1}} if
                  you operate on a calendar year. If your {{campaignYear}} annual report is
                  available, please attach instead. (Attachment E)
                  <table id="ctl00_pageContent_fuE_tblUpload">
                    <tbody>
                      <tr>
                        <td>
                          <span style="font-size: 75%; color: Blue"
                            >Please limit file size to 2048KB.</span
                          >
                          <app-seccfileuploader #attachE
                            [name]="'attachE'"
                            [uploadUrl]="'rest/secconlineapp/fincerts/uploadAttachmtE'"
                            [deleteUrl]="'secconlineapp/fincerts/deleteAttachmtE'"
                            [maxFileSize]="maxFileSz_finCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_finCert"
                            [maxFileCount]="maxFileCount_finCert"
                          >
                          </app-seccfileuploader>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span
                            id="ctl00_pageContent_fuE_lblError"
                            style="color: Red"
                          ></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  Copy of completed IRS Form 990 (not 990A) pages 1, 9 and 10
                  only <b>or IRS Form 990EZ Page 1 </b> (Attachment F)
                  <table id="ctl00_pageContent_fuF_tblUpload">
                    <tbody>
                      <tr>
                        <td>
                          <span style="font-size: 75%; color: Blue"
                            >Please limit file size to 2048KB.</span
                          >
                          <app-seccfileuploader #attachF
                            [name]="'attachF'"
                            [uploadUrl]="'rest/secconlineapp/fincerts/uploadAttachmtF'"
                            [deleteUrl]="'secconlineapp/fincerts/deleteAttachmtF'"
                            [maxFileSize]="maxFileSz_finCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_finCert"
                            [maxFileCount]="maxFileCount_finCert"
                          >
                          </app-seccfileuploader>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span
                            id="ctl00_pageContent_fuF_lblError"
                            style="color: Red"
                          ></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  J. If revenue exceeds $500,000, I certify that the
                  organization named in this application was (and its member
                  charity applicants were) audited in the immediately preceding
                  year (ending date on or after December 31, {{campaignYear -1}}) in accordance
                  with Generally Accepted Auditing Standards (GAAS) by an
                  independent certified public accountant.<br />
                  <table id="ctl00_pageContent_rblK" cellpadding="5" border="0">
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respK_1"
                            type="radio"
                            name="respK_1"
                            [value]="1"
                            [(ngModel)]="finCertsModel.respK"
                          /><label for="respK_1"
                            >YES</label
                          >
                        </td>
                        <td>
                          <input
                            id="respK_0"
                            type="radio"
                            name="respK_0"
                            [value]="0"
                            [(ngModel)]="finCertsModel.respK"
                          /><label for="respK_0)"
                            >NO</label
                          >
                        </td>
                        <td>
                           <input
                            id="respK_2"
                            type="radio"
                            name="respK"
                            [value]="2"
                            [(ngModel)]="finCertsModel.respK"
                          /><label for="respK_2)"
                            >Not Applicable (revenue under $500,000)</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span
                    id="ctl00_pageContent_rfvrblK"
                    style="color: Red; display: none"
                  ></span>
                  Copy of Certified Public Accountant's Opinion relating to the
                  financial statements provided. (Attachment G)
                  <table id="ctl00_pageContent_fuG_tblUpload">
                    <tbody>
                      <tr>
                        <td>
                          <span style="font-size: 75%; color: Blue"
                            >Please limit file size to 2048KB.</span
                          >
                          <app-seccfileuploader #attachG
                            [name]="'attachG'"
                            [uploadUrl]="'rest/secconlineapp/fincerts/uploadAttachmtG'"
                            [deleteUrl]="'secconlineapp/fincerts/deleteAttachmtG'"
                            [maxFileSize]="maxFileSz_finCert"
                            [allowMultiple]="false"
                            [fileType]="fileType_finCert"
                            [maxFileCount]="maxFileCount_finCert"
                          >
                          </app-seccfileuploader>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span
                            id="ctl00_pageContent_fuG_lblError"
                            style="color: Red"
                          ></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  K. I certify that the organization named in this application
                  has expended 25% or less of its total support and revenue on
                  management &amp; general and fundraising expenses plus any
                  association and/or federation fees and costs in the
                  immediately preceding year.<br />
                  <table
                    id="ctl00_pageContent_ynL_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respL_1"
                            type="radio"
                            name="respL_1"
                            [value]="true"
                            [(ngModel)]="finCertsModel.respL"
                          /><label for="respL_1"
                            >YES</label
                          >
                        </td>
                        <td>
                         <input
                            id="respL_0"
                            type="radio"
                            name="respL_0"
                            [value]="false"
                            [(ngModel)]="finCertsModel.respL"
                          /><label for="respL_0"
                            >NO</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span
                    id="ctl00_pageContent_ynL_rfvYesNo"
                    style="color: Red; display: none"
                  ></span>
                  <table width="100%">
                    <tbody>
                      <tr>
                        <td >

                        </td>
                        <td>
                          ❗Do not use commas in the numbers
                        </td>

                        <td></td>
                      </tr>
                      <tr>
                        <td class="label">
                          <span id="ctl00_pageContent_uclbl"
                            >Management &amp; General (IRS Form 990, Part IX,
                            Line 25, column C or <b>IRS Form 990EZ, Part I, Line 17.</b>)</span
                          >:
                        </td>
                        <td>

                          <input
                            name="txtManagement"
                            type="number"
                            maxlength="10"
                            id="txtManagement"
                            (change)="calculateTotalExp();"
                            [(ngModel)]="finCertsModel.l_A"
                            style="width: 170px; text-align: right"
                          /><span
                            id="ctl00_pageContent_revtxtManagement"
                            style="color: Red; display: none"
                          ></span>
                          <span
                            id="ctl00_pageContent_rfvtxtManagement"
                            style="color: Red; display: none"
                          ></span>
                        </td>

                        <td>(A)</td>
                      </tr>
                      <tr>
                        <td class="label">
                          <span id="ctl00_pageContent_Label1"
                            >Fundraising Expenses (IRS Form 990, Part IX, line
                            25, Column D or for <b>IRS Form 990EZ</b>, fundraising expenses are included above, place 0 on this line.)</span
                          >:
                        </td>
                        <td>
                          <input
                             name="txtFundraising"
                            type="number"
                            maxlength="10"
                            id="txtFundraising"
                            (change)="calculateTotalExp();"
                            [(ngModel)]="finCertsModel.l_B"
                            style="width: 170px; text-align: right"
                          /><span
                            id="ctl00_pageContent_revtxtFundraising"
                            style="color: Red; display: none"
                          ></span>
                          <span
                            id="ctl00_pageContent_rfvtxtFundraising"
                            style="color: Red; display: none"
                          ></span>
                        </td>
                        <td>(B)</td>
                      </tr>
                      <tr>
                        <td class="label1">
                          <span id="ctl00_pageContent_lblTE"
                            >TOTAL EXPENSES</span
                          >:
                        </td>
                        <td>
                          <span
                            id="ctl00_pageContent_lblTEVal"
                            style="
                              display: inline-block;
                              font-weight: bold;
                              width: 177px;
                              text-align: right;
                            "
                            >{{L_totalExpenses}}</span
                          >
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="label">
                          <span id="ctl00_pageContent_Label2"
                            >Total Revenue (IRS Form 990, Part VIII, Line 12,
                            Column A or <b>IRS Form 990EZ</b>, Part I, Line 9.)</span
                          >:
                        </td>
                        <td>

                          <input
                            name="txtTotalRevenue"
                            type="text"
                            maxlength="10"
                            id="txtTotalRevenue"
                            (change)="adminPerc();"
                            [(ngModel)]="finCertsModel.l_D"
                            style="width: 170px; text-align: right"
                          /><span
                            id="ctl00_pageContent_revtxtTotalRevenue"
                            style="color: Red; display: none"
                          ></span>
                          <span
                            id="ctl00_pageContent_rfvtxtTotalRevenue"
                            style="color: Red; display: none"
                          ></span>
                        </td>
                        <td>(C)</td>
                      </tr>
                      <tr>
                        <td class="label1">
                          <span id="ctl00_pageContent_lblAE"
                            >ADMINISTRATIVE EXPENSE PERCENTAGE</span
                          >:
                        </td>
                        <td>
                          <span
                            id="ctl00_pageContent_lblAEVal"
                            style="
                              display: inline-block;
                              font-weight: bold;
                              width: 177px;
                              text-align: right;
                            "
                            >{{L_adminExpensePctg}}</span
                          ><strong>%</strong>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  If your administrative overhead is above 25%, please provide a
                  detailed description of the cause of the overage and your plan
                  to reduce your administrative overhead to the required
                  percentage by next year.
                </td>
              </tr>
              <tr>
                <td>
                  Explanation:<br />
                    <textarea
                    name="explainL"
                    rows="3"
                    cols="20"
                    id="explainL"
                    [(ngModel)]="finCertsModel.explainL"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  L. I certify that the organization named in this application
                  meets all known, stated Eligibility Criteria of the SECC.<br />
                  <table
                    id="ctl00_pageContent_yneM_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <input
                            id="respM_1"
                            type="radio"
                            name="respM_1"
                            [value]="true"
                            [(ngModel)]="finCertsModel.respM"
                          /><label for="respM_1"
                            >YES</label
                          >
                        </td>
                        <td>
                          <input
                            id="respM_0"
                            type="radio"
                            name="respM_0"
                            [value]="false"
                            [(ngModel)]="finCertsModel.respM"
                          /><label for="respM_0)"
                            >NO</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                    <textarea
                    name="explainM"
                    rows="3"
                    cols="20"
                    id="explainM"
                    [(ngModel)]="finCertsModel.explainM"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_yneM_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  M. I certify that no officers, directors, or affiliated
                  organizations, including subsidiaries, partners, or parent
                  organizations of the organization named in this application
                  (and none of its member charity applicants), support or engage
                  in terrorist or violent activity.<br />
                  <table
                    id="ctl00_pageContent_yneN_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                         <input
                            id="respN_1"
                            type="radio"
                            name="respN_1"
                            [value]="true"
                            [(ngModel)]="finCertsModel.respN"
                          /><label for="respN_1"
                            >YES</label
                          >
                        </td>
                        <td>
                          <input
                            id="respN_0"
                            type="radio"
                            name="respN_0"
                            [value]="false"
                            [(ngModel)]="finCertsModel.respN"
                          /><label for="respN_0)"
                            >NO</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  If no, please explain:<br />
                    <textarea
                    name="explainN"
                    rows="3"
                    cols="20"
                    id="explainN"
                    [(ngModel)]="finCertsModel.explainN"
                    style="width: 80%"
                    maxlength="250"
                  ></textarea>
                  <span
                    id="ctl00_pageContent_yneN_rfv_rblYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  N. I certify that the organization named in this application
                  has not (and its member charity applicants have not) or will
                  not provide financial, technical, in-kind, or material support
                  or resources to any individual or entity, or agent thereof,
                  that we know, or have reason to know, advocates, plans,
                  sponsors, engages in, or has engaged in terrorist or violent
                  activity, including prohibited persons on a U.S. Government
                  issued list. Furthermore, this organization takes responsible
                  steps to ensure that our funds or resources are not used by
                  this organization or any organization to which these funds or
                  resources may be re-granted, distributed or processed, to
                  support terrorists or terrorist organizations, including
                  prohibited persons on a U.S. Government issued list.<br />
                  <table
                    id="ctl00_pageContent_ynO_rblYesNo"
                    cellpadding="5"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td>
                         <input
                            id="respO_1"
                            type="radio"
                            name="respO_1"
                            [value]="true"
                            [(ngModel)]="finCertsModel.respO"
                          /><label for="respO_1"
                            >YES</label
                          >
                        </td>
                        <td>
                          <input
                            id="respO_0"
                            type="radio"
                            name="respO_0"
                            [value]="false"
                            [(ngModel)]="finCertsModel.respO"
                          /><label for="respO_0)"
                            >NO</label
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <span
                    id="ctl00_pageContent_ynO_rfvYesNo"
                    style="color: Red; display: none"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <hr />
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: right">
                  <input
                    type="image"
                    width="100"
                    name="ctl00$pageContent$btnNext"
                    id="ctl00_pageContent_btnNext"
                    src="assets/img/continue-button.jpg"
                    (click)="continue()"
                    style="border-width: 0px"
                  />
                </td>
              </tr>
            </tbody>
          </table>

        </div>

        <div
          id="ctl00_pageContent_ModalPopupExtender_backgroundElement"
          class="modalBackground"
          style="display: none; position: fixed; left: 0px; top: 0px"
        ></div>
      </td>
    </tr>
  </tbody>
</div>
