<tbody>
  <tr>
    <td>
      <table
        border="0"
        cellspacing="0"
        style="background-color: White"
        cellpadding="5"
        width="800px"
        align="center"
      >
        <tbody>
            <tr>
              <td>
                <div  #valerrors
                  id="ctl00_ValidationSummary1"
                  style="color: Red"
                  [hidden]="!isValidationError"
                >
                  <h4>Please correct the following errors</h4>
                  <ul>
                    <li *ngFor="let error of errMessages">
                      {{ error }}
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
      </table>

      <div
        id="ctl00_pageContent_pnlContent"
        onkeypress="javascript:return WebForm_FireDefaultButton(event, 'ctl00_pageContent_btnNext')"
      >
        <table width="100%" id="table1">
          <tbody>
            <tr>
              <td align="center">
                <h3>SECC Soft File Requirements</h3>
                <br />
                (This information is required by independent charities,
                federations and federation charities)<br />
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" id="table2">
          <tbody>
            <tr>
              <td>
                <hr />
                &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <h3>1. Three (3) digital pictures</h3>
                Three (3) pictures of services provided or clients for whom
                services have been provided that can be used in our campaign -
                <em
                  ><strong
                    >provided in jpeg, bmp or gif format, 300 dpi</strong
                  ></em
                ><br />

                <table id="tab_clientOrServicePic">
                  <tbody>
                    <tr><td><p></p></td></tr>
                    <tr>
                      <td>
                        <span style="font-size: 75%; color: Blue"
                          >Please limit file size to 1024KB.</span
                        >
                      </td>
                    </tr>
                    <tr><td><p></p></td></tr>

                    <!-- New ROW-->
                    <tr>
                      <td>
                        <app-seccfileuploader   #digtlpics
                          [name]="'digtlpics'"
                          [uploadUrl]="'rest/secconlineapp/softfilereq/uploadDigitalPic'"
                          [deleteUrl]="'secconlineapp/softfilereq/deleteDigitalPic'"
                          [allowMultiple]="true"
                          [fileType]="fileType_1"
                          [maxFileSize]="maxFileSz_2"
                          [maxFileCount]="maxFileCount_1"
                        >
                        </app-seccfileuploader>
                      </td>
                    </tr>
                  </tbody>
                </table>
                Click the browse button and select one jpeg, bmp or gif picture.
                Click upload. Repeat these steps 2 times.
              </td>
            </tr>
            <tr>
              <td>
                <hr />
                &nbsp;
              </td>
            </tr>

            <!-- OLD ROW 2 -->
            <tr>
              <td>
                <h3>2. Two (2) True Stories</h3>
                Two (2) specific success stories comprised of direct health and
                human service, environmental and historical preservation
                services provided by your charity –
                <em
                  ><strong
                    >provided in Microsoft Word, 10 pt Arial font</strong
                  ></em
                ><br />
                <p></p>
                <div>
                  <span style="font-size: 75%; color: Blue">
                    Please limit file size to 1024KB.</span
                  >
                </div>
                <p></p>
                        <app-seccfileuploader #story
                          [name]="'story'"
                          [uploadUrl]="'rest/secconlineapp/softfilereq/uploadTrueStories'"
                          [deleteUrl]="'secconlineapp/softfilereq/deleteTrueStories'"
                          [allowMultiple]="true"
                          [fileType]="fileType_2"
                          [maxFileSize]="maxFileSz_2"
                          [maxFileCount]="maxFileCount_2"
                        >
                </app-seccfileuploader>
                Click the browse button and select your True Stories Document.
                This document must be in Microsoft Word (.doc) or Adobe Acrobat
                (.pdf). Once the document is in the field next to the browse
                button, click the upload button. Once you upload the document,
                the file name will appear. If you uploaded the wrong document,
                use the X button to remove the document and upload the correct
                document. Repeat if necessary.
              </td>
            </tr>
            <tr>
              <td>
                <hr />
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>

        <table width="100%" id="table3">
          <tbody>
            <tr>
              <td>
                <h3>3. Optional color logo</h3>
                One (1) digital color logo –
                <em
                  ><strong
                    >provided in jpeg, bmp or gif format, 300 dpi, dimensions
                    not exceeding 128px X 128px. This file must be labeled with
                    your charity name as you would like it to appear in the
                    campaign donor guide. If you’d like to have your color logo
                    appear in the online giving system, check yes and make your
                    $25 payment through PayPal as directed at the end of this
                    application.</strong
                  ></em
                ><br />
                <table id="ctl00_pageContent_fuLogo_tblUpload">
                  <tbody>
                    <tr><td>&nbsp;</td></tr>
                    <tr>
                      <td>
                        <span style="font-size: 75%; color: Blue">
                          Please limit file size to 1024KB.</span
                        >
                      </td>
                    </tr>
                   <tr><td>&nbsp;</td></tr>
                    <tr>
                      <td>
                        <app-seccfileuploader   #logo
                          [name]="'logo'"
                          [uploadUrl]="'rest/secconlineapp/softfilereq/uploadColorLogo'"
                          [deleteUrl]="'secconlineapp/softfilereq/deleteLogo'"
                          [allowMultiple]="true"
                          [fileType]="fileType_3"
                          [maxFileSize]="maxFileSz_3"
                          [maxFileCount]="maxFileCount_3"
                        >

                        </app-seccfileuploader>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </td>
            </tr>
            <tr>
              <td colspan="4" align="right">
                <input
                  type="image"
                  width="100"
                  name="ctl00$pageContent$btnNext"
                  id="ctl00_pageContent_btnNext"
                  src="assets/img/continue-button.jpg"
                  (click)="continue()"
                  style="border-width: 0px"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </td>
  </tr>
</tbody>
