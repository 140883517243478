<div>
  <table border="0" cellspacing="0" style="background-color: White" cellpadding="5" width="850px" align="center"
    class="shadow">
    <tbody>
      <tr>
        <td>
          <table border="0" cellspacing="0" style="background-color: White" cellpadding="5" width="800px"
            align="center">
            <tbody>
              <tr>
                <td>
                  <div id="ctl00_ValidationSummary1" style="color: Red; display: none"></div>
                </td>
              </tr>
            </tbody>
          </table>

          <h3></h3>
          <table width="100%">
            <tbody>
              <tr>
                <td colspan="2" style="text-align: center">
                  <h1>
                    <a id="top"><u>Please note the following before proceeding</u></a>
                  </h1>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <a href="#saving">Saving your Application</a><br />
                  <a href="#deadline">Application Deadline</a><br />
                  <a href="#previousinfo">Previous 2 Year Info</a><br />
                  <a href="#exemptions">Administrative Overhead Exemption</a><br />
                  <a href="#requirements">Minimum Requirements</a><br />
                  <a href="#documents">Document &amp; Other Information Required</a>
                  <hr />
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    <a id="saving">Saving your Application:</a>
                  </h3>
                </td>
                <td>
                  <a href="#top">top</a>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: solid 1px black">
                  <ul>
                    <li>
                      There is <strong>NOT</strong> an option to save the
                      application and return at a later time to complete it.
                      Therefore, you must make sure you have required
                      documentation and sufficient time available to complete
                      the entire application.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    <a id="deadline">Application Deadline:</a>
                  </h3>
                </td>
                <td>
                  <a href="#top">top</a>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: solid 1px black">
                  <ul>
                    <li>
                      All applications must be submitted by Friday May 30<sup>th</sup>, 2025 at 5pm
                      Arizona time. Late applications WILL be declined.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <h3><a id="previousinfo">Previous 2 Year Info:</a></h3>
                </td>
                <td>
                  <a href="#top">top</a>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: solid 1px black">
                  <ul>
                    <li>
                      If you have participated in SECC in 2023 and 2024, and have not received at least an average of
                      $250 by the SECC and State employees for the two consecutive years, per SECC policy, you shall not
                      be included for the SECC campaign for at least one campaign cycle. Subsequent readmission into the
                      SECC as an eligible charity may be considered by the SECC Executive Policy Committee on a case by
                      case basis, subject to evidence of State employee interest in the particular charity being
                      readmitted.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <h3><a id="exemptions">Administrative Overhead Exemption</a></h3>
                </td>
                <td><a href="#top">top</a></td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: solid 1px black">
                  <ul>
                    <li>
                      Based on our current SECC policy, if your administrative overhead was over 25% and you received an
                      exemption for the 2024 campaign, you will not receive an exemption this year. (Please do not
                      proceed with
                      the application).
                    </li>
                    <p></p>
                    <li>
                      If you need more information on this, please contact:
                      Executive Director, Linda Stiles.
                      <ul>
                        <li>Email: Linda.Stiles@azdoa.gov</li>
                        <li>Phone: (602) 542-7770</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    <a id="requirements">Minimum Requirements:</a>
                  </h3>
                </td>
                <td>
                  <a href="#top">top</a>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: solid 1px black">
                  <ul>
                    <li>
                      Please be sure you meet the minimum requirements and have
                      all backup documentation before beginning.
                      <br />
                      <br />
                      To be eligible for SECC participation an independent
                      charity, federation or its member charity applicants
                      shall:
                      <ol>
                        <li>
                          Be an entity comprised of direct health and/or human
                          service, environmental service, historical
                          preservation or animal welfare service.
                          <ol type="a">
                            <li>
                              Health services are defined as physical health or
                              behavioral health.
                            </li>
                            <li>
                              Human services are defined as basic needs,
                              developmental, emergency assistance, educational
                              or other direct services that help families and
                              individuals.
                            </li>
                            <li>
                              Environmental services are defined as those that
                              protect and enhance public health and the
                              environment.
                            </li>
                            <li>
                              Historical services are defined as those that
                              promote Arizona’s heritage.
                            </li>
                            <li>
                              Animal welfare services are defined as those that
                              promote and protect the health, safety and welfare
                              of companion animals in Arizona.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Be directed by an active Board of Directors, which
                          meets regularly and the majority of whose members
                          serve without compensation.
                        </li>
                        <li>
                          Adopt and employ the Standards of Accounting and
                          Financial Reporting for Voluntary non-profit
                          Organizations in accordance with Generally Accepted
                          Accounting Principles (GAAP). Prepare and make
                          available to the general public, and to the Chairman,
                          Director and the Executive Policy Committee Members,
                          an annual financial report prepared in accordance with
                          these standards. Organizations which report on IRS
                          Form 990 annual revenue in excess of $500,000 must
                          provide a copy of a certified public accountant’s
                          audit opinion relating to the financial statements.
                        </li>
                        <li>
                          All charities, regardless of IRS rules must submit a
                          signed IRS Form 990.
                        </li>
                        <li>
                          Provide accurate budget information which is used for
                          fundraising and administrative expenses;
                          <ol type="a">
                            <li>
                              The SECC shall not allow for immediate inclusion
                              of any independent charity which has
                              administrative costs in excess of 25%.
                            </li>
                            <li>
                              The SECC shall not allow for immediate inclusion of any federation member charity which
                              has combined administrative costs in excess of 25%. Combined administrative costs include any
                              deductions from contributions taken at the federation level plus their administrative costs
                              as reflected on their IRS Form 990 or 990EZ.
                            </li>
                            <li>
                              The SECC Executive Policy Committee reserves the
                              right to approve exceptions to 5a and 5b on a case
                              by case basis. A charity cannot be granted an
                              exception for more than one consecutive two year
                              campaign cycle.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Ensure that its publicity and promotional activities
                          are based upon its actual program and operations, are
                          truthful and non deceptive, and include all material
                          facts relative to the cause the organization
                          represents.
                        </li>
                        <li>
                          Verify that its publicity and promotional activities
                          assure protection against unauthorized use of its
                          contributor list; permit no payment of commissions;
                          kickbacks, finder’s fees, percentages, bonuses or
                          overrides for fundraising; and permit no paid
                          telephone solicitation of the public.
                        </li>
                        <li>
                          Demonstrate a direct and substantial presence in the
                          local community. This is defined as an organization
                          that has a staffed facility, office, or portion of a
                          residence located in Arizona, and is dedicated to that
                          organization and makes its services or benefits
                          available to members of the Arizona community.
                          (International or National level charities shall be
                          exempt from compliance with this requirement.)
                        </li>
                        <li>
                          Have a policy and demonstrate a practice of non
                          discrimination on the basis of race, color, religion,
                          sex, age, national origin or physical or mental
                          disability to persons who are served, to staff
                          employment and to membership of the governing board.
                          The policy must be Board approved, in written form and
                          available upon request to the SECC.
                        </li>
                        <li>
                          Not be constituent charities which are organized along
                          religious lines or which are organized to serve
                          persons of a specific gender or ethnicity. Such
                          charities may nevertheless meet eligibility
                          requirements if a bonafide purpose of organizing along
                          such lines can be shown. Compliance with the Arizona
                          Civil Rights Law (Title 41, Chapter 9) shall be deemed
                          as compliant with this section.
                        </li>
                        <li>
                          Must be in existence and in possession of IRS
                          501(c)(3) tax exempt status for a minimum of three
                          years.
                        </li>
                        <li>
                          Must provide a Federal EIN/Tax ID number. This will
                          ensure verification of IRS status to be identified as
                          a business entity. This will also verify that you are
                          in compliance with the Federal Patriot Act.
                        </li>
                        <li>
                          For best results, use Chrome or an updated and
                          supported browser.
                        </li>
                      </ol>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <h3>
                    <a id="documents">Document &amp; Other Information Required:</a>
                  </h3>
                </td>
                <td>
                  <a href="#top">top</a>
                </td>
              </tr>
              <tr>
                <td colspan="2" style="border-bottom: solid 1px black">
                  <ul>
                    <li>
                      Documents/information required to complete the application
                      are as follows:
                      <ol>
                        <li>Corporate name and fiscal year.</li>
                        <li>
                          A copy of the IRS determination letter of tax exempt
                          status under the United States Internal Revenue Code
                          26 U.S.C. § 501(c)(3) and to which contributions are
                          tax deductible pursuant to the United States Internal
                          Revenue Code 26 U.S.C. § 170.
                        </li>
                        <li>
                          Origin, purpose and structure of the organization,
                          including information to show that the health and/or
                          human service, environmental and historical
                          preservation charity meets the general and specific
                          requirements of these regulations. (Example: List of
                          Services, Company Brochure, Flyer)
                        </li>
                        <li>
                          In the case of service providing federations, a list
                          of member charity applicants and their addresses in
                          alphabetical order.
                        </li>
                        <li>
                          Outline of the charity’s program, particularly the
                          nature of the direct services provided by the charity.
                        </li>
                        <li>
                          List of the current Board members’ names, addresses
                          and business or profession.
                        </li>
                        <li>
                          All organizations reporting annual revenues in excess
                          of $500,000, must submit financial statements
                          certified by an independent public accountant of
                          compliance with the Generally Accepted Accounting
                          Principles (GAAP).
                        </li>
                        <li>
                          All organizations reporting annual revenues less than
                          $500,000 must submit financial statements and
                          self-certify compliance with GAAP.
                        </li>
                        <li>
                          Documentation of compliance with all eligibility
                          standards set forth in this section.
                        </li>
                        <li>
                          Copy of the latest annual report (within the past 12
                          months or most recent fiscal year). The fiscal year
                          reported must match the IRS Form 990 time period.
                        </li>
                        <li>Email address</li>
                        <li>
                          Three digital pictures of services provided or clients
                          for whom services have been provided that can be used
                          in our campaign.
                        </li>
                        <li>
                          Two specific success stories comprised of direct
                          health and human service, environmental and historical
                          preservation services provided by your charity.
                        </li>
                        <li>
                          Any other documentation deemed necessary by the
                          applications committee.
                        </li>
                        <li>
                          All charities, regardless of IRS rules must submit a
                          signed IRS Form 990.
                        </li>
                        <li>Must provide a federal EIN/Tax ID number.</li>
                        <li>
                          For additional information, please go to
                          <a href="http://www.azsecc.org/files/applications.htm">secc.az.gov</a>
                        </li>
                      </ol>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
              </tr>
              <tr>
                <td align="right" colspan="2">
                  <button type="button" name="button" (click)="startNewApp()">Start New Application</button>
                  <!--<span class="triangle"></span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <br />
</div>
